/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from 'theme-ui';
import { Text, Box, Flex, Card, AspectImage } from 'theme-ui';
import axios from 'axios';
import Color from 'color'
import dayjs from 'dayjs'


const ShoutCard = ({ bizID, isHome }) => {
  const [shout, setShout] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    axios
      .get('/.netlify/functions/partner', {
        params: {
          bizID: bizID
        }
      })
      .then(response => {
        setShout(response.data.items[0]);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [])

  const renderShoutCard = () => {
    const dominantColor = shout.business.avatar.image.dominantColor;
    const color = Color(dominantColor)
    return (
      <Card
        sx={{
          width: isHome ? '100%' : '375px',
          boxShadow: '0 0 30px rgba(0,0,0,.3)',
          borderRadius: '13px',
        }}>
        <Box sx={{ borderRadius: '132x' }}>
          {shout.image.isDefault ? (
            <AspectImage
              sx={{
                borderTopRightRadius: '12px',
                borderTopLeftRadius: '12px',
                height: '100%',
                width: '100%',
              }}
              ratio={16 / 9}
              src={`https://res.cloudinary.com/gonation/f_auto,q_auto,g_face,c_lfill,w_400/${shout.business.avatar.image.cloudinaryId}`}></AspectImage>
          ) : (
            <AspectImage
              sx={{}}
              ratio={16 / 9}
              sx={{
                height: '100%',
                borderTopRightRadius: '12px',
                borderTopLeftRadius: '12px',
                width: '100%',
              }}
              src={`https://res.cloudinary.com/gonation/${shout.image.image.cloudinaryId}`}></AspectImage>
          )}
        </Box>
        <Box
          sx={{
            background: 'white',
            borderBottomRightRadius: '12px',
            borderBottomLeftRadius: '12px',
            padding: 3,
            minHeight: '100px',
          }}>
          <Text sx={{marginBottom: '3', fontSize: 1, fontWeight: '600'}}>
            {dayjs(shout.createdAt).format('dddd, MMMM DD h:mm A')}
          </Text>
          <Text sx={{ lineHeight: 2, pb: 4, fontWeight: '500', fontSize: 3 }}>{shout.text}</Text>
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              as='span'
              variant="heading"
              sx={{
                fontWeight: 'heading',
                paddingX: 4,
                paddingY: 2,
                borderRadius: '13px',
                background: dominantColor,
                color: color.isLight() ? 'text' : '#fff',
                fontSize: 3
              }}>
              Recent Shout
            </Text>
          </Flex>
        </Box>
      </Card>
    );
  };

  // return <p>jfs</p>
  return !isLoading ? renderShoutCard() : '';
};

export default ShoutCard;
